export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AddRushPricingSummaryResult: [
      "AddRushPricingSummary",
      "AddRushPricingSummaryError",
    ],
    AddressVerificationResult: [
      "AddressVerificationError",
      "VerifiedHomeWiseAddress",
      "VerifiedHomeWiseAddressWrongHOA",
      "VerifiedPostalAddress",
    ],
    BaseMutationError: [
      "AcknowledgeHomeownerPrivacyRightsError",
      "AddRushMutationError",
      "BasicSaveQnrDataError",
      "CancelOrderMutationError",
      "CopyHoaQnrDataMutationError",
      "CopyHoaQnrSettingsMutationError",
      "CreateFollowUpQnrQuestionMutationError",
      "CreatePrimaryQnrQuestionMutationError",
      "DeleteQnrQuestionMutationError",
      "GenericMutationError",
      "LoginMutationError",
      "ModifyBuyerMutationError",
      "ModifyOrderMutationError",
      "PlaceOrderMutationError",
      "PostSaveQnrDataError",
      "ReleaseQnrQuestionMutationError",
      "ReorderFollowUpQnrQuestionsMutationError",
      "RequestOrderUpdateMutationError",
      "SaveHoaQnrExpirationSettingsMutationError",
      "SaveHoaQnrQuickUpdateSettingsMutationError",
      "SaveMgmtQnrAcctgImportSettingsMutationError",
      "SaveMgmtQnrDefaultContactSettingsMutationError",
      "SaveMgmtQnrOptInSettingsMutationError",
      "SaveQnrDataSmartValidationError",
      "UpdateFollowUpQnrQuestionMutationError",
      "UpdatePrimaryQnrQuestionMutationError",
      "UpdateQnrLayoutMutationError",
    ],
    BasicFollowUpQnrQuestion: [
      "FollowUpDropdownQnrQuestion",
      "FollowUpMultiSelectQnrQuestion",
      "FollowUpTextLikeQnrQuestion",
    ],
    CreditCardFeeInfo: ["CreditCardFeeDisabled", "FlatCreditCardFee"],
    FollowUpQnrQuestion: [
      "FollowUpBespokeQnrQuestion",
      "FollowUpDropdownQnrQuestion",
      "FollowUpMultiSelectQnrQuestion",
      "FollowUpTextLikeQnrQuestion",
    ],
    FollowUpQnrQuestionWithResponse: [
      "FollowUpDropdownQnrQuestionWithResponse",
      "FollowUpMultiSelectQnrQuestionWithResponse",
      "FollowUpTextLikeQnrQuestionWithResponse",
      "QnrCommercialUsesWithResponse",
      "QnrMultiUnitOwnersWithResponse",
    ],
    GenerateLightboxParamsResult: [
      "ConvergeLightboxParams",
      "GenerateLightboxParamsError",
    ],
    HoaQnrAccountingSystemDataResult: [
      "HoaQnrAccountingSystemData",
      "HoaQnrAccountingSystemError",
    ],
    HoaQnrDataResult: ["HoaQnrData", "HoaQnrDataError"],
    HoaQnrExpirationSettingsResult: [
      "HoaQnrExpirationSettings",
      "HoaQnrExpirationSettingsError",
    ],
    HoaQnrQuickUpdateSettingsResult: [
      "HoaQnrQuickUpdateSettings",
      "HoaQnrQuickUpdateSettingsError",
    ],
    MgmtProductPerfMap: [
      "MgmtPerfByALaCarteProductType",
      "MgmtPerfByBundleType",
    ],
    MgmtQnrAcctgImportSettingsResult: [
      "MgmtQnrAcctgImportSettings",
      "MgmtQnrAcctgImportSettingsError",
    ],
    MgmtQnrOptInQuestionSettingsResult: [
      "MgmtQnrOptInQuestionSettings",
      "MgmtQnrOptInQuestionSettingsError",
    ],
    MutationResponse: [
      "AcknowledgeHomeownerPrivacyRightsMutationResponse",
      "AddNewUserToRequestorOrgResponse",
      "AddRushMutationResponse",
      "AttachDocumentToOrderMutationResponse",
      "CancelOrderMutationResponse",
      "ChangePasswordMutationResponse",
      "CopyHoaQnrDataMutationResponse",
      "CopyHoaQnrSettingsMutationResponse",
      "CreateFollowUpQnrQuestionMutationResponse",
      "CreatePrimaryQnrQuestionMutationResponse",
      "DeleteQnrQuestionMutationResponse",
      "DeleteRemittanceBatchGroupMutationResponse",
      "LoginMutationResponse",
      "ModifyBuyerMutationResponse",
      "ModifyOrderMutationResponse",
      "PayForOrderMutationResponse",
      "PlaceOrderMutationResponse",
      "ProcessRemittanceBatchRunMutationResponse",
      "ReleaseQnrQuestionMutationResponse",
      "ReorderFollowUpQnrQuestionsMutationResponse",
      "RequestForgotUsernameEmailMutationResponse",
      "RequestOrderUpdateMutationResponse",
      "RequestPasswordResetEmailMutationResponse",
      "ResetPasswordMutationResponse",
      "SaveHoaQnrExpirationSettingsMutationResponse",
      "SaveHoaQnrQuickUpdateSettingsMutationResponse",
      "SaveKeyMetricsFiltersMutationResponse",
      "SaveMgmtQnrAcctgImportSettingsMutationResponse",
      "SaveMgmtQnrDefaultContactSettingsMutationResponse",
      "SaveMgmtQnrOptInSettingsMutationResponse",
      "SaveMgmtRemittanceVendorSettingsMutationResponse",
      "SaveProductPerfFiltersMutationResponse",
      "SaveProductProcessingFiltersMutationResponse",
      "SaveRemittanceBatchGroupMutationResponse",
      "SaveRushPerfFiltersMutationResponse",
      "SaveTrendsFiltersMutationResponse",
      "SignUpMutationResponse",
      "StartProcessingProductMutationResponse",
      "UpdateFollowUpQnrQuestionMutationResponse",
      "UpdatePrimaryQnrQuestionMutationResponse",
      "UpdateQnrLayoutMutationResponse",
      "UpdateRequestorProfileResponse",
    ],
    Order: ["CancelledOrder", "CompletedOrder", "PendingOrder", "UnpaidOrder"],
    OrderLookupResult: ["OrderLookupError", "OrderLookupSuccess"],
    OrderPricingSummaryResult: [
      "OrderPricingSummary",
      "OrderPricingSummaryError",
    ],
    OrderProduct: ["QnrOrderProduct"],
    OrderUpdateInfoUnion: ["CantUpdateReason", "InfoForUpdateRequest"],
    PayForOrderPricingSummaryResult: [
      "PayForOrderPricingSummary",
      "PayForOrderPricingSummaryError",
    ],
    ProductInterface: ["Bundle", "IndividualItem"],
    QnrContactSetting: ["CompanyEmployee", "QnrGenericCompanyContactSetting"],
    QnrOrderProductResult: ["QnrOrderProduct", "QnrOrderProductError"],
    QnrQuestion: [
      "DropdownQnrQuestion",
      "MultiSelectQnrQuestion",
      "TextLikeQnrQuestion",
    ],
    QnrQuestionAccountingSystemData: [
      "DropdownQnrQuestionAccountingSystemData",
      "TextLikeQnrQuestionAccountingSystemData",
    ],
    QnrQuestionExpirationSettings: [
      "QnrQuestionCalendarExpiration",
      "QnrQuestionDayCountExpiration",
    ],
    QnrSectionItemWithExpirationSettings: [
      "QnrQuestionWithExpirationSettings",
      "QnrSubsectionWithExpirationSettings",
    ],
    QnrSectionItemWithOptInQuestionSettings: [
      "QnrOptInQuestionWithOptInSettings",
      "QnrSubsectionWithOptInQuestionSettings",
    ],
    QnrSectionItemWithQuickUpdateSettings: [
      "QnrQuestionWithQuickUpdateSettings",
      "QnrSubsectionWithQuickUpdateSettings",
    ],
    QnrSectionItemWithResponse: [
      "DropdownQnrQuestionWithResponse",
      "MultiSelectQnrQuestionWithResponse",
      "QnrSubsectionWithResponses",
      "TextLikeQnrQuestionWithResponse",
    ],
    QnrSubsectionQuestionWithResponse: [
      "DropdownQnrQuestionWithResponse",
      "MultiSelectQnrQuestionWithResponse",
      "TextLikeQnrQuestionWithResponse",
    ],
    RemittanceAmountInterface: [
      "HoaRemittanceBatchItem",
      "MgmtCompanyHoaRemittanceAmounts",
      "MgmtCompanyRemittanceAmounts",
      "MgmtCompanyRemittanceBatchItem",
      "RemittanceBatchRun",
      "RemittanceReportSummary",
    ],
    RemittanceBatchGroupInterface: [
      "MgmtCompanyRemittanceBatchGroup",
      "RemittanceBatchGroup",
    ],
    RemittanceBatchInterface: [
      "RemittanceBatch",
      "RemittanceBatchRun",
      "RemittanceReportBatch",
    ],
    RemittanceBatchItem: [
      "HoaRemittanceBatchItem",
      "MgmtCompanyRemittanceBatchItem",
    ],
    RemittanceBatchItemInterface: [
      "HoaRemittanceBatchItem",
      "MgmtCompanyHoaRemittanceAmounts",
      "MgmtCompanyRemittanceAmounts",
      "MgmtCompanyRemittanceBatchItem",
    ],
    RemittanceBatchItemWithAmountsAndDetailsInterface: [
      "MgmtCompanyHoaRemittanceAmounts",
      "MgmtCompanyRemittanceAmounts",
    ],
    RemittanceReportInvoice: [
      "HoaRemittanceReportInvoice",
      "MgmtCompanyRemittanceReportInvoice",
    ],
    RemittanceReportInvoiceInterface: [
      "HoaRemittanceReportInvoice",
      "MgmtCompanyRemittanceReportInvoice",
    ],
    RemittanceVendorSettingsInterface: [
      "HoaRemittanceVendorSettings",
      "MgmtCompanyRemittanceVendorSettings",
    ],
    RequestOrderUpdatePricingSummaryResult: [
      "OrderPricingSummary",
      "RequestOrderUpdatePricingSummaryError",
    ],
    SaveQnrDataError: [
      "BasicSaveQnrDataError",
      "PostSaveQnrDataError",
      "SaveQnrDataSmartValidationError",
    ],
    SimpleQnrSectionItemForLayout: [
      "SimpleQnrQuestionForLayout",
      "SimpleQnrSubsectionForLayout",
    ],
    TextLikeQnrQuestionResponse: [
      "DateQnrQuestionResponse",
      "FloatQnrQuestionResponse",
      "IntQnrQuestionResponse",
      "PlainTextQnrQuestionResponse",
      "UsdQnrQuestionResponse",
    ],
  },
};
export default result;
